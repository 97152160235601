import React, {Component} from 'react';
import styles from "./screener.module.css";
import DesktopContainer from "../../container/DesktopContainer";
import {graphql, StaticQuery} from "gatsby";
import MobileContainer from "../../container/MobileContainer";
import Announcement from "../recent/Announcement";
import {Grid, Header} from "semantic-ui-react";
import Cover from 'react-video-cover';
import video from "../../assets/website-bg.mp4";
import cx from 'classnames';
import Img from "gatsby-image";
import ShowVideoButton from "../utils/buttons/ShowVideoButton";

class Screener extends Component {
    state = {
        visible: true,
        resizeNotifier: () => {
        },
    };

    render() {
        const videoOptions = {
            src: video,
            autoPlay: true,
            muted: true,
            loop: true,
        };
        return (
            <div className={styles.main}>
                <StaticQuery query={screenerQuery}
                             render={data => (
                                 <>
                                     <MobileContainer className={styles.mobile}>
                                         <div>
                                             <Img fluid={data.imageOne.childImageSharp.fluid}/>
                                         </div>
                                     </MobileContainer>
                                     <DesktopContainer className={styles.desktop}>
                                         <Grid className={styles.buttons} columns={'equal'} centered>
                                             <Grid.Column>
                                                 <Header className={styles.header} as={'h1'}>Wir helfen hautkranken
                                                     Kindern und Jugendlichen</Header>
                                                 <div className={styles.videoButton}>
                                                     <ShowVideoButton link={'/video/'}>
                                                         Zum Video
                                                     </ShowVideoButton>
                                                 </div>
                                             </Grid.Column>
                                         </Grid>
                                         <Announcement/>
                                             <div id={"screenBgImg"}
                                                 className={styles.backgroundImg}
                                             >
                                                 <div className={styles.videoBackground}>

                                                     <div id='screenVideo' className={styles.videoForeGround}>
                                                         <Cover
                                                             id={'bongo'}
                                                             className={cx('bongo', styles.videoIframe)}
                                                             videoOptions={videoOptions}
                                                             remeasureOnWindowResize
                                                             getResizeNotifier={resizeNotifier => {
                                                                 this.setState({
                                                                     resizeNotifier,
                                                                 });
                                                             }}
                                                         />
                                                     </div>
                                                 </div>
                                             </div>
                                     </DesktopContainer>
                                 </>
                             )}
                />
            </div>

        )}
}

export default Screener;

const screenerQuery = graphql`
  query screenerQuery {
    imageOne: file(relativePath: { eq: "snap1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    imageTwo: file(relativePath: { eq: "snap4.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

