import '../styles/global.css';
import '../styles/semantic.min.css';
import React from "react";
import {Container, Grid} from "semantic-ui-react";
import styles from "../templates/layout.module.css";
import LayoutContainer from "../container/LayoutContainer";
import SEO from "../components/seo/Seo";
import MainNavigation from "../components/navigation/MainNavigation";
import Screener from "../components/screener/Screener";
import Recent from "../components/recent/Recent";
import Footer from "../components/footer/Footer";
import ContactPreview from "../components/contact/ContactPreview";
import SupporterPreview from "../components/supporters/SupporterPreview";
import DesktopContainer from "../container/DesktopContainer";
import Announcement from "../components/recent/Announcement";
import PromoVideo from "../components/promo-video/PromoVideo";
import MobileTabletContainer from "../container/MobileTabletContainer";
import LogoCarousel from "../components/carousel/LogoCarousel";
import AmazonSmile from "../components/amazon_smile/AmazonSmile";

const Start = () => (<LayoutContainer>
        <SEO/>
        <MobileTabletContainer>
            <MainNavigation page={'start'}/>
            <Announcement/>
            <PromoVideo/>
            <Recent/>
            <LogoCarousel/>
            <ContactPreview/>
            <AmazonSmile/>
            <SupporterPreview/>
            <Footer/>
        </MobileTabletContainer>
        <DesktopContainer>
            <div className={styles.pageWrapper}>
                <MainNavigation page={'start'}/>
                <Screener/>
                <Container fluid style={{marginTop: '3em', marginBottom: '2em'}}>
                    <Grid centered columns={3}>
                        <Grid.Column width={10}>
                            <Recent/>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <ContactPreview/>
                            <AmazonSmile/>
                            <SupporterPreview/>
                        </Grid.Column>
                    </Grid>
                    <LogoCarousel/>
                </Container>
                <Footer/>
            </div>
        </DesktopContainer>
    </LayoutContainer>
);

export default Start;